<template>
  <div
    data-aos="fade-right"
    class="card-item-modulo mt-3"
    data-toggle="collapse"
    :data-target="'#collapseModulo' + id"
    aria-expanded="false"
    :aria-controls="'collapseModulo' + id"
  >
    <div
      class="card-item-modulo-header align-items-center d-flex"
      :id="'headingModulo' + id"
    >
      <h2 class="mb-0 p-0  text-left text-modulo" v-if="title">
        {{ title }}
      </h2>
      <img
        src="@/assets/Images/Formation/Icon awesome-chevron-down.png"
        alt="icon-arrow"
      />
    </div>

    <div
      :id="'collapseModulo' + id"
      class="collapse"
      :aria-labelledby="'headingModulo' + id"
      :data-parent="accordion"
    >
      <div class="card-body">
        <template v-if="!links">
          <div>
            <div class="content" v-html="description"></div>
            <button v-if="link || external" class="btn btn-sc" @click="goTo">
              {{ locale[lang].QUESTION.BUTTON }}
            </button>
          </div>
        </template>
        <template v-else>
          <div v-for="(item, key) in links" :key="key">
            <div class="content" v-html="item.description"></div>
            <button
              v-if="item.article"
              class="btn btn-sc"
              @click="goToArticule(item.link, item.article)"
            >
              {{ locale[lang].QUESTION.BUTTON }}</button
            ><button
              v-else-if="item.link"
              class="btn btn-sc"
              @click="goToParam(item.link)"
            >
              {{ locale[lang].QUESTION.BUTTON }}
            </button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "id",
    "title",
    "description",
    "accordion",
    "link",
    "paramId",
    "external",
    "article",
    "links",
  ],
  methods: {
    goToArticule(link, article) {
      this.$router.push({
        name: link,
        params: { id: article.id, year: article.year },
      });
    },
    goToParam(link) {
      this.$router.push({ name: link });
    },
    goTo() {
      if (this.external) {
        window.open(this.external, "_blank");
      } else if (this.article != null) {
        this.$router.push({
          name: this.link,
          params: { id: this.article.id, year: this.article.year },
        });
        window.localStorage.setItem("postID", this.article.id);
      } else if (this.paramId) {
        this.$router.push({ name: this.link, params: { id: this.paramId } });
      } else {
        this.$router.push({ name: this.link });
      }
    },
  },
};
</script>

<style scoped>
.card-item-modulo {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 15px #0000001a;
  border-radius: 15px;
  opacity: 1;
  padding: 38px 52px 30px 55px;
  cursor: pointer;
  margin-bottom: 40px;
}

.text-modulo {
  text-align: left;
  font: normal normal bold 26px/32px Montserrat;
  letter-spacing: 0px;
  color: #080808;
  opacity: 1;
  padding-left: 1.3rem;
}

.card-body >>> p {
  text-align: left;
  font: normal normal 500 18px/32px Montserrat;
  letter-spacing: 0px;
  color: #080808;
  opacity: 1;
}

.card-item-modulo-header {
  justify-content: space-between;
}

.card-item-modulo-header img {
  height: 12px !important;
  width: 20px !important;
}

@media (max-width: 425px) {
  .card-item-modulo {
    border: 1px solid #d5d5d5;
  }

  .card-body >>> p {
    font-size: 16px;
    line-height: 18px;
  }
}
</style>
