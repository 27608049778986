<template>
  <div>
    <div class="container-fluid" id="nav-container">
      <nav-bar></nav-bar>
    </div>
    <div class="container-fluid" id="container">
      <div id="firstQ" v-if="itemId === 'firstQ'">
        <div class="row">
          <div class="col-12 title-sec">
            <h1>{{locale[lang].QUESTION.FIRST.TITLE}}</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="square">
              <h2>
                {{locale[lang].QUESTION.FIRST.SQUARE.TITLE}}
              </h2>
              <ul>
                <li>
                  <p v-html="locale[lang].QUESTION.FIRST.SQUARE.ITEMS.TEXT_1"></p>
                  <button
                    class="btn btn-sc"
                    @click="goTo('BlogCategory', locale[lang].QUESTION.FIRST.SQUARE.LINKS[0])"
                  >
                    {{ locale[lang].QUESTION.BUTTON }}
                  </button>
                </li>
                <li>
                  <p v-html="locale[lang].QUESTION.FIRST.SQUARE.ITEMS.TEXT_2"></p>
                  <button
                    class="btn btn-sc"
                    @click="goTo('BlogCategory', locale[lang].QUESTION.FIRST.SQUARE.LINKS[1])"
                  >
                    {{ locale[lang].QUESTION.BUTTON }}
                  </button>
                </li>
                <li>
                  <p v-html="locale[lang].QUESTION.FIRST.SQUARE.ITEMS.TEXT_3"></p>
                  <button
                    class="btn btn-sc"
                    @click="goTo('BlogCategory', locale[lang].QUESTION.FIRST.SQUARE.LINKS[2])"
                  >
                    {{ locale[lang].QUESTION.BUTTON }}
                  </button>
                </li>
                <li>
                  <p v-html="locale[lang].QUESTION.FIRST.SQUARE.ITEMS.TEXT_4"></p>
                  <button
                    class="btn btn-sc"
                    @click="goTo('BlogCategory', locale[lang].QUESTION.FIRST.SQUARE.LINKS[3])"
                  >
                    {{ locale[lang].QUESTION.BUTTON }}
                  </button>
                </li>
                <li>
                  <p v-html="locale[lang].QUESTION.FIRST.SQUARE.ITEMS.TEXT_5"></p>
                  <button
                    class="btn btn-sc"
                    @click="goTo('BlogCategory', locale[lang].QUESTION.FIRST.SQUARE.LINKS[4])"
                  >
                    {{ locale[lang].QUESTION.BUTTON }}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-sec">
            <div class="accordeon">
              <div class="accordion" id="accordion1">
                <ItemQuestion
                  :id="item.id"
                  :title="item.title"
                  v-for="(item, index) in locale[lang].QUESTION.FIRST.LIST"
                  :key="index"
                  :description="item.description"
                  :accordion="'#accordion1'"
                  :link="item.link"
                  :article="item.article"
                  :paramId="item.paramId"
                  :external="item.external"
                  :links="item.links"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="secondQ" v-if="itemId === 'secondQ'">
        <div class="row">
          <div class="col-12 title-sec">
            <h1>{{locale[lang].QUESTION.SECOND.TITLE}}</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-sec">
            <div class="accordion" id="accordion2">
              <ItemQuestion
                :id="item.id"
                :title="item.title"
                v-for="(item, index) in locale[lang].QUESTION.SECOND.LIST"
                :key="index"
                :description="item.description"
                :accordion="'#accordion2'"
                :link="item.link"
                :article="item.article"
                :paramId="item.paramId"
                :external="item.external"
                :links="item.links"
              />
            </div>
          </div>
        </div>
      </div>

      <div id="thirdQ" v-if="itemId === 'thirdQ'">
        <div class="row">
          <div class="col-12 title-sec">
            <h1>{{locale[lang].QUESTION.THIRD.TITLE}}</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-sec">
            <div class="accordion" id="accordion3">
              <ItemQuestion
                :id="item.id"
                :title="item.title"
                v-for="(item, index) in locale[lang].QUESTION.THIRD.LIST"
                :key="index"
                :description="item.description"
                :accordion="'#accordion3'"
                :link="item.link"
                :article="item.article"
                :paramId="item.paramId"
                :external="item.external"
                :links="item.links"
              />
            </div>
          </div>
        </div>
      </div>

      <div id="fourthQ" v-if="itemId === 'fourthQ'">
        <div class="row">
          <div class="col-12 title-sec">
            <h1>{{locale[lang].QUESTION.FOURTH.TITLE}}</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-sec">
            <div class="accordion" id="accordion4">
              <ItemQuestion
                :id="item.id"
                :title="item.title"
                v-for="(item, index) in locale[lang].QUESTION.FOURTH.LIST"
                :key="index"
                :description="item.description"
                :accordion="'#accordion4'"
                :link="item.link"
                :article="item.article"
                :paramId="item.paramId"
                :external="item.external"
              />
            </div>
          </div>
        </div>
      </div>

      <div id="fifthQ" v-if="itemId === 'fifthQ'">
        <div class="row">
          <div class="col-12 title-sec">
            <h1>{{locale[lang].QUESTION.FIFTH.TITLE}}</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-sec">
            <div>
              <div class="content">
                <h5>
                  {{locale[lang].QUESTION.FIFTH.LIST.FAQ_1.TEXT}}
                </h5>
                <button
                  class="btn btn-sc"
                  @click="goTo('BlogCategory', locale[lang].QUESTION.FIFTH.LINK_1)"
                >
                  {{locale[lang].QUESTION.FIFTH.LIST.FAQ_1.BUTTON}}
                </button>
              </div>

              <div class="content">
                <p>
                  {{locale[lang].QUESTION.FIFTH.LIST.FAQ_2.TEXT}}
                </p>
                <button class="btn btn-sc" @click="goPage('GuideManagement')">
                  {{locale[lang].QUESTION.FIFTH.LIST.FAQ_2.BUTTON}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="sixQ" v-if="itemId === 'sixQ'">
        <div class="row">
          <div class="col-12 title-sec">
            <h1>{{locale[lang].QUESTION.SIX.TITLE}}</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-sec">
            <div class="accordion" id="accordion6">
              <ItemQuestion
                :id="item.id"
                :title="item.title"
                v-for="(item, index) in locale[lang].QUESTION.SIX.LIST"
                :key="index"
                :description="item.description"
                :accordion="'#accordion6'"
                :link="item.link"
                :paramId="item.paramId"
                :external="item.external"
                :links="item.links"
                :article="item.article"
              />
            </div>
          </div>
        </div>
      </div>

      <div id="seventhQ" v-if="itemId === 'seventhQ'">
        <div class="row">
          <div class="col-12 title-sec">
            <h1>{{locale[lang].QUESTION.SEVENTH.TITLE}}</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-sec">
            <div>
              <div class="content">
                <ul class="list">
                  <li>
                    {{locale[lang].QUESTION.SEVENTH.LIST.FAQ_1.TEXT}}
                  </li>
                </ul>
                <button class="btn btn-sc" @click="goPage('Blog')">
                  {{locale[lang].QUESTION.SEVENTH.LIST.FAQ_1.BUTTON}}
                </button>
              </div>
              <br />
              <div class="content">
                <ul class="list">
                  <li>
                    {{locale[lang].QUESTION.SEVENTH.LIST.FAQ_2.TEXT}}
                  </li>
                </ul>
                <button class="btn btn-sc" @click="goPage('Home')">
                  {{locale[lang].QUESTION.SEVENTH.LIST.FAQ_2.BUTTON}}
                </button>
              </div>
              <br />
              <div class="content">
                <ul class="list">
                  <li>
                    {{locale[lang].QUESTION.SEVENTH.LIST.FAQ_3.TEXT}}
                  </li>
                </ul>
                <button class="btn btn-sc" @click="goPage('GuideJeunes')">
                  {{locale[lang].QUESTION.SEVENTH.LIST.FAQ_3.BUTTON}}
                </button>
              </div>
              <div class="content">
                <ul class="list">
                  <li>
                    {{locale[lang].QUESTION.SEVENTH.LIST.FAQ_4.TEXT}}
                  </li>
                </ul>
                <button
                  class="btn btn-sc"
                  @click="goExternal(locale[lang].QUESTION.SEVENTH.LIST.FAQ_4.LINK)"
                >
                  {{locale[lang].QUESTION.SEVENTH.LIST.FAQ_4.BUTTON}}
                </button>
              </div>
              <br />
              <div class="content">
                <ul class="list">
                  <li>
                    {{locale[lang].QUESTION.SEVENTH.LIST.FAQ_5.TEXT}}
                  </li>
                </ul>
                <button class="btn btn-sc" @click="openMenu('exercices')">
                  {{locale[lang].QUESTION.SEVENTH.LIST.FAQ_5.BUTTON}}
                </button>
              </div>
              <br />
              <div class="content">
                <ul class="list">
                  <li>
                    {{locale[lang].QUESTION.SEVENTH.LIST.FAQ_6.TEXT}}
                  </li>
                </ul>
                <button class="btn btn-sc" @click="openMenu('programmes')">
                  {{locale[lang].QUESTION.SEVENTH.LIST.FAQ_6.BUTTON}}
                </button>
              </div>
              <br />
              <div class="content">
                <ul class="list">
                  <li>
                    {{locale[lang].QUESTION.SEVENTH.LIST.FAQ_7.TEXT}}
                  </li>
                </ul>
                <button class="btn btn-sc" @click="openMenu('formation')">
                  {{locale[lang].QUESTION.SEVENTH.LIST.FAQ_7.BUTTON}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="eighthQ" v-if="itemId === 'eighthQ'">
        <div class="row">
          <div class="col-12 title-sec">
            <h1>{{ locale[lang].QUESTION.EIGHTH.TITLE }}</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-sec">
            <div>
              <p>
                {{ locale[lang].QUESTION.EIGHTH.CONTENT.TEXT_1 }}
              </p>
              <p v-html="locale[lang].QUESTION.EIGHTH.CONTENT.TEXT_2">
                {{ locale[lang].QUESTION.EIGHTH.CONTENT.TEXT_2 }}
                
                {{ locale[lang].QUESTION.EIGHTH.CONTENT.TEXT_3 }}
                <a href="https://entrainementdefoot.fr/" target="_blank">https://entrainementdefoot.fr/</a>
                .
              </p>
              <p>
                {{ locale[lang].QUESTION.EIGHTH.CONTENT.TEXT_3 }}
              </p>
              <p>
                {{ locale[lang].QUESTION.EIGHTH.CONTENT.TEXT_4 }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid" id="footer-container">
      <Footer />
    </div>
  </div>
</template>

<script>
import ItemQuestion from "@/components/ItemQuestion.vue";
import NavBar from "@/components/essential/NavBar.vue";
import Footer from "@/components/essential/Footer.vue";
export default {
  components: {
    ItemQuestion,
    NavBar,
    Footer,
  },
  methods: {
    openMenu(route) {
      this.$store.state.showMenu = true;
      this.$store.state.menuOption = route;
    },
    goPage(link) {
      this.$router.push({ name: link });
    },
    goTo(link, id) {
      this.$router.push({ name: link, params: { id: id } });
    },
    goExternal(link) {
      window.open(link, "_blank");
    },
  },
  created() {},
  computed: {
    itemId() {
      return this.$route.params.id;
    },
  },
};
</script>

<style scoped>
.container-fluid {
  padding: 0;
}

#firstQ,
#secondQ,
#thirdQ,
#fourthQ,
#sixQ,
#seventhQ {
  padding: 0 70px;
  background-image: url("../assets/Images/Background/Trazado 1903.png"),
    url("../assets/Images/Blog/Enmascarar grupo 133.png"),
    url("../assets/Images/Blog/Enmascarar grupo 134.png"),
    url("../assets/Images/Background/Grupo 736.png"),
    url("../assets/Images/Background/Trazado 1903.png"),
    url("../assets/Images/Blog/Grupo 691.png"),
    url("../assets/Images/Background/Grupo 685.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 2% 17%, 100% 40%, 100% 65%, 50% 100%, 0% 80%, 93% 88.8%,
    92% 10%;
  padding-bottom: 10%;
}

#fifthQ,
#eighthQ {
  padding: 0 70px;
  background-image: url("../assets/Images/Blog/Enmascarar grupo 134.png"),
    url("../assets/Images/Background/Grupo 736.png"),
    url("../assets/Images/Background/Trazado 1903.png"),
    url("../assets/Images/Background/Grupo 685.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 100% 65%, 50% 100%, 0% 80%, 92% 10%;
  padding-bottom: 10%;
}

.square {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 15px #0000001a;
  margin-top: 2rem;
  margin-bottom: 30px;
  padding: 3rem 1rem;
  border-radius: 16px;
}

.text-sec {
  margin: 35px 0;
}

.accordion >>> .card-body {
  text-align: left;
}

.btn-sc,
.accordion >>> .btn-sc {
  margin: 1rem 0 2rem;
  display: block;
  font: normal normal bold 22px/22px Montserrat;
  border-radius: 15px;
  padding: 25px 50px;
  background-image: url("../assets/Images/Background/ball.png");
  background-repeat: no-repeat;
  background-position: 100% 100%;
  /* border: 0.4px solid #0f1010 !important; */
  box-shadow: 0px 3px 30px #00000029;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  color: #0f1010;
  background-size: 95px 80px;
  margin-bottom: 20px;
}

.btn-sc:hover,
.accordion >>> .btn-sc:hover {
  background-color: rgba(209, 209, 209, 0.507);
  margin-left: 12px;
}

.blue,
.accordion >>> .blue {
  color: blue;
}

a,
.red,
.accordion >>> a,
.accordion >>> .red {
  color: #f9334a;
  font-weight: 500;
}

#eighthQ >>> a {
  color: #f9334a;
  /* line-break: anywhere; */
}

h1,
.accordion >>> h1 {
  text-align: left;
  font: normal normal 800 38px/47px Montserrat;
  letter-spacing: 0px;
  color: #f9334a;
  margin-top: 60px;
  margin-bottom: 30px;
}

h2 {
  font: normal normal 800 30px/40px Montserrat;
  letter-spacing: 0px;
  color: #0f1010;
  margin-bottom: 30px;
}

.accordion >>> h2 {
  font: normal normal bold 26px/32px Montserrat;
  letter-spacing: 0px;
  color: #080808;
  margin-bottom: 30px;
}

p,
.accordion >>> p {
  text-align: justify;
  font: normal normal 500 22px/34px Montserrat;
  letter-spacing: 0.24px;
  color: #0f1010;
  margin-bottom: 20px;
}

li,
.accordion >>> li {
  font: normal normal 800 24px/34px Montserrat;
  letter-spacing: 0.24px;
  color: #0f1010;
  margin-bottom: 20px;
}

h5,
.accordion >>> h5 {
  font: normal normal 800 24px/34px Montserrat;
  color: #0f1010;
  margin: 40px 0 30px 0;
}

.square li p {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.45;
}

.list li,
.accordion >>> .list li {
  font-weight: 500;
}

li,
p,
h5,
.content,
.accordion >>> li,
.accordion >>> p,
.accordion >>> h5,
.accordion >>> .content {
  text-align: left;
}

hr {
  border: 2px solid rgba(0, 0, 0, 0.3);
}

@media (max-width: 1024px) {
  h1 {
    font-size: 24px;
    line-height: 30px;
  }
}

@media (max-width: 825px) {
  #firstQ,
  #secondQ,
  #thirdQ,
  #fourthQ,
  #sixQ,
  #seventhQ,
  #fifthQ,
  #eighthQ {
    padding: 0 1.25rem;
    background-size: 50%;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  .btn-sc,
  .accordion >>> .btn-sc {
    padding: 0.75rem 1rem;
    background-size: contain;
    margin: 1rem 0;
    width: 100%;
  }

  .accordion >>> .card-item-modulo {
    padding: 30px 32px 30px 24px;
  }

  .accordion >>> h2 {
    font-size: 20px;
    line-height: 22px;
  }

  .accordion >>> h1,
  h1 {
    font-size: 28px;
    line-height: 30px;
  }

  h2 {
    font-size: 22px;
    line-height: 24px;
  }

  .accordion >>> li,
  li,
  .accordion >>> h5,
  h5 {
    font-size: 20px;
    line-height: 28px;
  }

  .accordion >>> p {
    font-size: 18px;
    line-height: 24px;
  }

  .content {
    text-align: center;
  }
}
</style>
